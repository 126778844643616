// 10Mb

import { mapActions, mapGetters } from 'vuex';
import draggable from 'vuedraggable';
import PhotoBox from './PhotoBox/index.vue';
import AddPhotoButton from './AddPhotoButton/index.vue';
import createId from '../../../helpers/id';

const MAX_SIZE = 5242880 * 2;

export default {
  components: {
    PhotoBox,
    AddPhotoButton,
    draggable,
  },
  name: 'PhotoReport',
  i18n: require('./i18n').default,
  props: ['isOnSiteVerifying'],
  data() {
    return {
      files: [],
      photoReportLink: '',
      videoReportLink: '',
    };
  },
  computed: {
    ...mapGetters({
      langPrefix: 'lang/prefix',
      autotestData: 'TechnicalCheck/autotestData',
      reportPhotos: 'TechnicalCheck/reportPhotos',
      reportData: 'TechnicalCheck/reportData',
    }),
  },
  created() {
    this.files = this.reportPhotos;
    this.photoReportLink = this.reportData?.mainObject?.photoReportLink;
    this.videoReportLink = this.reportData?.mainObject?.videoReportLink;

    this.$on('changeVideoReport', (videoReport) => {
      this.$parent.$emit('changeVideoReport', videoReport);
    });
  },
  methods: {
    ...mapActions({
      changePhotosOrder: 'others/autotest/changePhotosOrder',
    }),
    showModal(flag) {
      this.$parent.$emit('onShowModal', flag);
    },
    openPhotoPicker() {
      this.$refs.photos.value = '';
      this.$refs.photos.click();
    },
    onPhotosListChange(event) {
      let isThereBigPhotos = false;

      const { target: { files = [] } = {} } = event;
      const filesList = Array
        .from(files)
        .filter((item) => {
          const isSmaller = item.size < MAX_SIZE;
          if (!isSmaller) {
            isThereBigPhotos = true;
          }
          return isSmaller;
        })
        .map((item = {}) => ({ image: item, id: createId() }));
      this.files.push(...filesList);

      if (isThereBigPhotos) {
        // @todo выводить адекватное оповещение
        alert('Слишком большое фото');
      }
    },
    deleteItem(index, photoId) {
      this.$http.delete(`/autotest/photos/${photoId}`);

      if (Number.isInteger(index)) {
        this.files.splice(index, 1);

        if (index === this.main) {
          this.main = undefined;
        }
        this.emitPhotoDataChanged();
      }
    },
    emitPhotoDataChanged() {
      const data = this.files
        .map((file, index) => {
          const { hash, description, angle } = file;
          return {
            hash, description, angle, main: (this.main || 0) === index,
          };
        });
      this.$emit('photosDataChanged', data);
    },
    uploadItem(index, hash, path) {
      if (Number.isInteger(index)) {
        const {
          [index]: item = {},
        } = this.files;
        item.hash = hash;
        item.path = path;
        this.emitPhotoDataChanged();
      }
    },
    replacePhoto({ oldIndex, newIndex }) {
      const withoutChanges = oldIndex < 0
				|| oldIndex >= this.files.length
				|| newIndex < 0
				|| newIndex >= this.files.length;

      if (!withoutChanges) {
        const sortedPhotos = this.files.sort((less, more) => less.sorting_index - more.sorting_index);
        const [photo] = sortedPhotos.splice(oldIndex, 1);
        sortedPhotos.splice(newIndex, 0, photo);

        const changedPhotos = sortedPhotos.slice(
          Math.min(oldIndex, newIndex),
          Math.max(oldIndex, newIndex) + 1,
        );
        const offset = Math.min(oldIndex, newIndex);

        changedPhotos.forEach((photo, index) => {
          const newSortingIndex = offset + index;
          if (photo.sorting_index !== newSortingIndex) {
            photo.sorting_index = newSortingIndex;
          }
        });

        return this.changePhotosOrder(changedPhotos);
      }
    },
  },
  watch: {
    files: {
      handler() {
        this.$parent.$emit('changePhotoReport', this.files);
      },
      deep: true,
    },
    photoReportLink: {
      handler() {
        this.$parent.$emit('addPhotoReportLink', this.photoReportLink);
      },
      deep: true,
    },
    videoReportLink: {
      handler() {
        this.$parent.$emit('addVideoReportLink', this.videoReportLink);
      },
      deep: true,
    },
  },
};
