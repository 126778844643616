export default {
  name: 'ProgressBar',
  props: {
    progressWidth: Number,
    default: 0,
    validator(value) {
      return value <= 100;
    },
  },
};
