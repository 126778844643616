export default {
  messages: {
    uk: {
      'Переконайтесь, що формат відео коректний. Підтримуються формати': 'Переконайтесь, що формат відео коректний. Підтримуються формати {value}',
    },
    ru: {
      'Переконайтесь, що формат відео коректний. Підтримуються формати': 'Убедитесь, что формат видео корректен. Поддерживаются форматы {value}',
    },
  },
};
