import Hls from 'hls.js';

export default {
  name: 'VideoPlayer',
  data() {
    return {
      isPlaying: false,
      isButtonVisible: true,

      hlsInstance: null,
      videoPlayer: null,
      isLoading: true,
    };
  },
  props: {
    videoID: String,
  },
  computed: {
    videoSrc() {
      return `https://files.ria.com/auto/messages/video/44/${this.videoID}/playlist.m3u8`;
    },
    videoPoster() {
      return `https://files.ria.com/messages/video/44/${this.videoID}/first.jpg`;
    },
  },
  methods: {
    togglePlayPause() {
      const video = this.$refs.videoPlayer;

      if (this.isPlaying) {
        video.pause();
      } else {
        video.play();
      }
      this.isPlaying = !this.isPlaying;
    },
    onVideoEnded() {
      this.isButtonVisible = true;
      this.isPlaying = false;
    },
    showButton() {
      this.isButtonVisible = true;
    },
    hideButton() {
      this.isButtonVisible = false;
    },

    async checkStreamAvailability(url) {
      try {
        const response = await fetch(url, { method: 'HEAD' });
        if (response.ok) {
          return true;
        }
        console.error('checkStreamAvailability status', response.status);
        return false;
      } catch (error) {
        console.error('checkStreamAvailability error', error);
        return false;
      }
    },

    initHlsPlayer(video) {
      if (Hls.isSupported()) {
        this.hlsInstance = new Hls({
          maxBufferSize: 15,
          startLevel: 1,
        });

        this.hlsInstance.loadSource(this.videoSrc);
        this.hlsInstance.attachMedia(video);

        this.hlsInstance.on(Hls.Events.LEVEL_SWITCHED, () => {
          this.isLoading = false;
        });

        this.hlsInstance.on(Hls.Events.FRAG_LOADED, () => {
          if (this.hlsInstance.media && this.hlsInstance.media.readyState >= 3) {
            this.isLoading = false;
          }
        });
      } else {
        console.error('HLS не підтримується');
      }
    },
  },
  mounted() {
    const video = this.$refs.videoPlayer;

    this.checkStreamAvailability(this.videoSrc)
      .then((isAvailable) => {
        if (isAvailable) {
          this.initHlsPlayer(video);
        } else {
          console.error('checkStreamAvailability, недоступно для завантаження');
        }
      })
      .catch((error) => {
        console.error('checkStreamAvailability, error', error);
      });
  },

  beforeDestroy() {
    if (this.hlsInstance) {
      this.hlsInstance.destroy();
    }
  },
};
