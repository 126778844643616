export default {
  messages: {
    uk: {
      Додати: 'Додати',
      відео: 'відео',
    },
    ru: {
      Додати: 'Добавить',
      відео: 'видео',
    },
  },
};
