export default {
  messages: {
    uk: {
      Додати: 'Додати',
    },
    ru: {
      Додати: 'Добавить',
    },
  },
};
