import translate from './i18n';

export default {
  name: 'LoadVideo',
  data() {
    return {
      isPlaying: false,
      isButtonVisible: true,
    };
  },
  methods: {
    handleFileChange(event) {
      this.$emit('selected-files', event);
    },
  },
  i18n: {
    ...translate,
  },
};
