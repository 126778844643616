import * as tus from 'tus-js-client';
import { mapGetters } from 'vuex';

import draggable from 'vuedraggable';
import Vue from 'vue';
import VideoPlayer from './VideoPlayer/index.vue';
import ProgressBar from './ProgressBar/index.vue';
import LoadVideo from './LoadVideo/index.vue';
import translate from './i18n';

const TUS_URL = 'https://write.ria.com:1043/files/';
const CHUNK_SIZE_MB = 10;

const FORMATS_FOR_MESSAGE = 'MP4, MOV';
const MAX_MIN_DURATION = 3;

export default {
  data() {
    return {
      videos: [],
      progress: {},
    };
  },
  name: 'VideoReport',
  components: {
    draggable,
    VideoPlayer,
    ProgressBar,
    LoadVideo,
  },
  computed: {
    ...mapGetters({
      videoReport: 'TechnicalCheck/videoReport',
      autotestData: 'TechnicalCheck/autotestData',
    }),
    isEmptyProgress() {
      return Object.keys(this.progress).length === 0;
    },
  },
  created() {
    this.videos = this.videoReport;
  },
  methods: {
    cleaningProgress(key) {
      const newProgress = { ...this.progress };
      if (key && Object.prototype.hasOwnProperty.call(this.progress, key)) {
        delete newProgress[key];
      }
      this.progress = newProgress;
    },

    deleteItem(index, id) {
      if (Number.isInteger(index)
          && index >= 0
          && this.videos[index]
          && this.videos[index].id === id) {
        // this.$http.delete(`/autotest/videos/${id}`);
        this.videos.splice(index, 1);
      } else {
        console.error('Невідповідність id та index');
      }
    },

    replaceItem({ oldIndex, newIndex }) {
      const isOldIndexInvalid = oldIndex < 0 || oldIndex >= this.videos.length;
      const isNewIndexInvalid = newIndex < 0 || newIndex >= this.videos.length;
      if (isOldIndexInvalid || isNewIndexInvalid) {
        return;
      }
      const sortedItems = this.videos.sort((a, b) => a.sorting_index - b.sorting_index);

      const [item] = sortedItems.splice(oldIndex, 1);
      sortedItems.splice(newIndex, 0, item);

      const changedItems = sortedItems.slice(
        Math.min(oldIndex, newIndex),
        Math.max(oldIndex, newIndex) + 1,
      );

      const offset = Math.min(oldIndex, newIndex);

      changedItems.forEach((changeItem, changeIndex) => {
        const newSortingIndex = offset + changeIndex;
        if (changeItem.sorting_index !== newSortingIndex) {
          item.sorting_index = newSortingIndex;
        }
      });
    },

    validateFilesByType(files) {
      return Object.values(files).filter(({ type }) => {
        const [formatType, format] = type.split('/');
        const unsupportedFormatsInBrowser = ['avi', 'mkv'];
        return formatType.includes('video') && !unsupportedFormatsInBrowser.includes(format);
      });
    },

    async getVideoData(file) {
      return new Promise((resolve, reject) => {
        const videoElement = document.createElement('video');
        videoElement.preload = 'metadata';
        videoElement.onloadedmetadata = () => resolve(videoElement);
        videoElement.onerror = (error) => reject(error);
        videoElement.src = URL.createObjectURL(file);
      });
    },

    getError(error) {
      return { error };
    },

    async validateFiles(files) {
      const validFiles = this.validateFilesByType(files);
      if (validFiles.length === 0) {
        return this.getError(
          this.$t('Переконайтесь, що формат відео коректний. Підтримуються формати', {
            value: FORMATS_FOR_MESSAGE,
          }),
        );
      }

      try {
        for (const file of validFiles) {
          const { duration } = await this.getVideoData(file);

          if (duration > MAX_MIN_DURATION * 60) {
            return this.getError(
              this.$t('Переконайтесь, що тривалість відео не перевищує', { value: MAX_MIN_DURATION * 60 }),
            );
          }
        }
        return validFiles;
      } catch (error) {
        return this.getError(this.$t('Сталася помилка при обробці відео.'));
      }
    },

    async saveVideo(video) {
      const videoReports = this.videoReport;

      const body = {
        id: video.successTusKey,
        sorting_index: video.sorting_index,
      };
      videoReports.push(body);
      this.videos = videoReports;
      // this.cleaningProgress(video.successTusKey);
      this.$parent.$emit('changeVideoReport', videoReports);
    },

    startVideoUpload(video) {
      video.tusInstance.findPreviousUploads().then((previousUploads) => {
        if (previousUploads.length) {
          video.tusInstance.resumeFromPreviousUpload(previousUploads[0]);
        }
        video.tusInstance.start();
      });
    },

    createTusUploadInstance(video) {
      const options = {
        endpoint: TUS_URL,
        retryDelays: [0, 3000, 5000, 10000, 20000],
        chunkSize: 1024 * 1024 * CHUNK_SIZE_MB,
        metadata: {
          filename: video.file.name,
          filetype: video.file.type,
        },
      };

      if (video.file) {
        video.tusInstance = new tus.Upload(video.file, {
          ...options,
          onError: (error) => {
            console.error(`Failed upload video: ${error}`);
          },
          onProgress: (bytesUploaded, bytesTotal) => {
            // const key = video.tusInstance?.url?.split('/').at(-1);
            // const progress = Number(((bytesUploaded / bytesTotal) * 100).toFixed(0));
            // Vue.set(this.progress, key, progress);
          },
          onSuccess: async () => {
            video.successTusKey = video.tusInstance?.url?.split('/').at(-1);
            video.sorting_index = this.videos.length + 1;
            await this.saveVideo(video);
            // eslint-disable-next-line no-undef
            localStorage.removeItem('uploadProgress');
          },
        });
        this.startVideoUpload(video);
      } else {
        console.error('Файл відсутній');
      }
    },

    async change(files) {
      // eslint-disable-next-line no-restricted-syntax
      for (const file of files) {
        const video = {
          file,
        };

        try {
          // eslint-disable-next-line no-await-in-loop
          const result = await this.validateFiles([file]);
          if (result.error) {
            video.error = result.error;
          } else {
            this.createTusUploadInstance(video);
          }
        } catch (error) {
          video.error = error.message || 'Сталася неочікувана помилка';
        }
      }
    },

    handleFileChange({ target }) {
      this.change(target.files);
    },
  },
  i18n: {
    ...translate,
  },
};
